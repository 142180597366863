import { template as template_da937ffd3fc24d1ca784fe72a5a7e7ad } from "@ember/template-compiler";
const FKLabel = template_da937ffd3fc24d1ca784fe72a5a7e7ad(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
