import { template as template_908b2481bb1e48e8804720a560fed6f4 } from "@ember/template-compiler";
const WelcomeHeader = template_908b2481bb1e48e8804720a560fed6f4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
