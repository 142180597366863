import { template as template_ffc80120ad10430e843ef7036dfea4e7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ffc80120ad10430e843ef7036dfea4e7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
