import { template as template_f6d62bb071664cf1a472740448b24e35 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_f6d62bb071664cf1a472740448b24e35(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
